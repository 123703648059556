<template>
	<div class="table-responsive">
		<table class="table table-striped- table-bordered table-hover table-checkable" id="m_table_1">
			<thead style="background:#f0f0f0">
				<tr>
					<th nowrap>Canal</th>
					<th nowrap>Tipo de Campanha</th>
					<th nowrap>ID da Campanha</th>
					<th nowrap>Keyword</th>
					<th nowrap>Provedor de Mídia</th>
					<th nowrap>Oferta</th>
					<th nowrap>OfferKey</th>
					<th nowrap>Nome</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(campaign, index) in campaigns" :key="index">
					<td nowrap>{{ campaign.media_channel.name }}</td>
					<td nowrap>{{ campaign.type.name }}</td>
					<td nowrap>{{ campaign.campaign_id }}</td>
					<td nowrap>{{ campaign.keyword || '-' }}</td>
					<td nowrap>{{ campaign.company.name }}</td>
					<td nowrap>{{ campaign.offer.name }}</td>
					<td nowrap>{{ campaign.offer.key }}</td>
					<td nowrap>{{ campaign.name }}</td>
					<td>
						<a
							v-if="$authorization.can('campaign-update')"
							href="javascript:;"
							@click="edit(campaign)"
							title="Configurar"
							data-toggle="modal"
							data-target="#modal"
						>
							<i class="flaticon-cogwheel-2 ml-3"></i>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import CampaignService from "../../services/CampaignService";
	export default {
		name: "campaign-list",

		props: {
			campaigns: {
				type: Array,
				default: () => {
					return [];
				}
			}
		},

		data() {
			return {
				campaignService: new CampaignService()
			};
		},

		methods: {
			edit(campaign) {
				this.$emit("edit", campaign);
			}
		}
	};
</script>

<style>
	.table td {
		padding: 20px;
	}
</style>
