<template>
  <div>
    <div
      class="modal fade"
      id="modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form id="form">
            <div class="modal-header">
              <h5 class="modal-title" id="modalLabel">Nova campanha</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="alert alert-danger" role="alert" v-show="validates.length">
                <ul v-for="(validate, index) in validates" :key="index">
                  <li>{{ validate }}</li>
                </ul>
              </div>

              <div class="m-portlet__body">
                <div class="row mb-3">
                  <section class="col-6 form-group">
                    <div class="m-form__group">
                      <label for="offter_id">Oferta</label>
                      <select
                        v-validate="'required'"
                        data-vv-name="oferta"
                        class="form-control m-input"
                        id="offter_id"
                        v-model="campaign.offer_id"
                        @change="prepareKeywords"
                      >
                        <option
                          v-for="(offer, index) in offers"
                          :key="index"
                          :value="offer.id"
                        >{{ offer.name }}</option>
                      </select>
                      <div class="text-danger">{{ errors.collect("oferta")[0] }}</div>
                    </div>
                  </section>

                  <section class="col-6 form-group">
                    <div class="m-form__group">
                      <label class="ml-4">Tipo de Campanha</label>
                      <br />
                      <label class="m-radio ml-4" v-for="(type, index) in types" :key="index">
                        <input
                          v-validate="'required'"
                          data-vv-name="Tipo de Campanha"
                          type="radio"
                          name="type_id"
                          :value="type.id"
                          v-model="campaign.type_id"
                          @change="prepareKeywords"
                        />
                        {{ type.name }}
                        <span></span>
                      </label>
                      <div class="text-danger">{{ errors.collect("Tipo de Campanha")[0] }}</div>
                    </div>
                  </section>
                </div>

                <div class="row mb-3">
                  <section class="col-6 form-group">
                    <div class="m-form__group">
                      <label for="media_channel_id">Canal</label>
                      <select
                        v-validate="'required'"
                        data-vv-name="Canal"
                        class="form-control m-input"
                        id="media_channel_id"
                        v-model="campaign.media_channel_id"
                        @change="getChannels(campaign.media_channel_id)"
                      >
                        <option
                          v-for="(channel,
                          index) in mediaChannelService.channels"
                          :key="index"
                          :value="channel.id"
                        >{{ channel.name }}</option>
                      </select>
                      <div class="text-danger">{{ errors.collect("Canal")[0] }}</div>
                    </div>
                  </section>

                  <section class="col-6 form-group">
                    <div class="m-form__group">
                      <label for="company_id">Provedor de Mídia</label>
                      <select
                        v-validate="'required'"
                        data-vv-name="Provedor de Mídia"
                        class="form-control m-input"
                        id="company_id"
                        disabled
                        v-model="campaign.company_id"
                        title="Escolha um canal para habilitar os provedores de mídia"
                      >
                        <template v-show="providers.length">
                          <option
                            v-for="(provider, index) in providers"
                            :key="index"
                            :value="provider.id"
                          >{{ provider.name }}</option>
                        </template>
                      </select>
                      <div class="text-danger">{{ errors.collect("Provedor de Mídia")[0] }}</div>
                    </div>
                  </section>

                  <section class="col-12 form-group" v-if="keywords.length">
                    <div class="m-form__group">
                      <label for="company_id">Keywords:</label>
                      <select
                        v-show="!loading"
                        v-validate="'required'"
                        data-vv-name="Keyword"
                        class="form-control m-input"
                        id="company_id"
                        v-model="campaign.keyword"
                        title="Escolha um canal para habilitar os provedores de mídia"
                      >
                        <option value></option>
                        <option
                          v-for="(keyword, index) in keywords"
                          :key="index"
                          :value="keyword"
                        >{{ keyword }}</option>
                      </select>

                      <div
                        v-show="loading"
                        class="m-loader m-loader--danger"
                        style="width: 30px; display: inline-block;"
                      ></div>

                      <div class="text-danger">{{ errors.collect("Keyword")[0] }}</div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <hr />
            <div class="pt-1 pb-4 pr-5 pl-5">
              <div class="clearfix"></div>
              <div class="row">
                <div class="col-6">
                  <button
                    v-show="
                      campaign.id != null &&
                        $authorization.can('campaign-destroy')
                    "
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    @click="destroy(campaign)"
                  >Excluir Campanha</button>
                </div>

                <div class="col-6 text-right">
                  <button
                    type="button"
                    class="btn btn-secondary mr-4"
                    data-dismiss="modal"
                    @click="close"
                  >Cancelar</button>
                  <button
                    @click="save"
                    type="button"
                    :class="
                      `btn btn-info ${
                        saving ? 'm-loader m-loader--light m-loader--left' : ''
                      }`
                    "
                  >
                    <span :class="saving ? 'pl-2' : ''">{{ saving ? "Salvando.." : "Salvar" }}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfferService from "../../services/OfferService";
import MediaChannelService from "../../services/MediaChannelService";
import CampaignService from "../../services/CampaignService";

export default {
  name: "campaign-form",

  props: {
    serviceId: {
      type: [Number, String],
      required: true
    },

    campaign: {
      type: Object,
      required: true
    }
  },

  watch: {
    campaign() {
      if (this.campaign.id != null) {
        this.getChannels(this.campaign.media_channel_id);
      } else {
        this.clear();
      }
    }
  },

  async mounted() {
    this.offerService.offers(this.serviceId).then(response => {
      this.offers = response.data;
    });

    await this.mediaChannelService.index(this.serviceId);
    this.types = await this.campaignService.getTypes(this.serviceId);

    $(".modal").on("hide.bs.modal", e => {
      this.clear();
    });
  },

  data() {
    return {
      offerService: new OfferService(),
      offers: [],
      mediaChannelService: new MediaChannelService(),
      campaignService: new CampaignService(),
      campaigns: [],
      types: [],
      loading: false,
      saving: false,
      validates: [],
      providers: [],
      keywords: []
    };
  },

  methods: {
    clear() {
      this.$validator.reset();
      this.prepareKeywords();
      this.providers = [];
      this.validates = [];
      this.getChannels(this.campaign.media_channel_id);
    },

    close() {
      $(".close").click();
    },

    async save() {
      const isValid = this.validateKeyword();

      this.$validator.validateAll().then(async result => {
        if (result && isValid) {
          this.saving = true;

          if (this.campaign.id != null) {
            this.validates = await this.campaignService.update(
              this.serviceId,
              this.campaign
            );

            this.saving = false;
            return this.validator();
          }

          this.validates = await this.campaignService.store(
            this.serviceId,
            this.campaign
          );

          this.saving = false;
          return this.validator();
        }
      });
    },

    validateKeyword() {
      const channel = this.findMediaChannel(
        "id",
        this.campaign.media_channel_id
      );

      if (
        ["Adwise", "SmartMessage", "WIB Push"].includes(channel.name) &&
        !this.campaign.keyword
      ) {
        swal({
          title: "Escolha uma keyword",
          text: "Para as informações fornecidas deve haver uma keyword",
          type: "warning"
        });

        return false;
      }

      return true;
    },

    validator() {
      if (!this.validates.length) {
        toastr.success("Salvo com sucesso!", {
          positionClass: "toast-bottom-left"
        });

        this.close();
        this.$emit("created", true);
      }
    },

    findMediaChannel(propety, value) {
      return this.mediaChannelService.channels.find(channel => {
        if (channel[propety] == value) {
          return channel;
        }
      });
    },

    prepareKeywords() {
      this.keywords = [];

      if (this.campaign.media_channel_id) {
        const channel = this.findMediaChannel(
          "id",
          this.campaign.media_channel_id
        );

        if (
          ["Adwise", "SmartMessage", "WIB Push"].includes(channel.name) &&
          this.campaign.offer_id &&
          this.campaign.media_channel_id &&
          this.campaign.type_id
        ) {
          return this.getKeywords(this.campaign);
        }

        this.campaign.keyword = null;
      }
    },

    getKeywords(params) {
      this.loading = true;
      axios
        .get("api/keyword/service-campaign/all", {
          params: {
            offer_id: params.offer_id,
            media_channel_id: params.media_channel_id,
            category_keyword_id: params.type_id
          }
        })
        .then(response => {
          this.loading = false;
          this.keywords = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    getChannels(channel_id) {
      this.prepareKeywords();
      this.providers = [];

      this.mediaChannelService.channels.find(channel => {
        if (channel.id == channel_id) {
          this.providers = channel.company_media_channels;

          if (this.providers.length) {
            $("#company_id").removeAttr("disabled");
            $("#company_id").val(company_id);
          }
        }
      });
    },

    destroy(campaign) {
      swal({
        title: "Tem certeza que deseja Deletar?",
        text: "Uma vez deletado não poderá mais recuperar esse registro.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo deletar"
      }).then(willDelete => {
        if (willDelete.value) {
          this.$emit("destroy", campaign);

          swal("Sucesso!", "Deletado com sucesso.", "success");
        }
      });
    }
  }
};
</script>

<style>
.modal-content {
  width: 700px !important;
}
</style>
