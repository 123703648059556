/* global axios */
export default class MediaChannelService {
  constructor() {
    this.axios = axios;
    this.channels = [];
    this.isLoading = false;
  }

  async index(serviceId) {
    this.isLoading = true;

    return this.axios.get(`/service/${serviceId}/campaigns/channels`).
      then((response) => {
        this.channels = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
