<template>
	<div v-if="!campaignService.isLoading && $authorization.can('service-campaign-list')">
		<header-service :service="service" currentTab="Campanha" :showActions="false"></header-service>

		<div class="row mt-5">
			<div class="col-lg-3 col-md-4">
				<menu-service :service="service"></menu-service>
			</div>

			<div class="col-lg-9 col-md-8">
				<div class="row">
					<div class="col-12">
						<div>
							<div class="m-portlet m-portlet--mobile">
								<div class="m-portlet__head">
									<div class="m-portlet__head-caption">
										<div class="m-portlet__head-title">
											<h3 class="m-portlet__head-text">Campanhas</h3>
										</div>
									</div>

									<div class="m-portlet__head-tools" v-if="$authorization.can('campaign-store')">
										<ul class="m-portlet__nav">
											<li class="m-portlet__nav-item">
												<button
													data-toggle="modal"
													data-target="#modal"
													id="add"
													class="btn btn-accent m-btn m-btn--custom m-btn--pill m-btn--icon m-btn--air"
													@click="add"
												>
													<span>
														<i class="la la-plus"></i>
														<span>Nova</span>
													</span>
												</button>
											</li>
										</ul>
									</div>
								</div>

								<div>
									<CampaignForm
										:serviceId="$route.params.id"
										@created="getAllCampaign()"
										:campaign="campaign"
										@destroy="destroy($event)"
									/>
									<div class="mb-3">
										<CampaignList
											:campaigns="campaigns"
											v-if="campaigns.length"
											:key="render"
											@edit="edit($event)"
										/>
									</div>
									<p class="text-center p-3 pb-4" v-if="!campaigns.length">Nenhuma campanha cadastrada</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CampaignService from "../services/CampaignService";
	import ServiceService from "../services/ServiceService";
	import MenuService from "../components/MenuService.vue";
	import HeaderService from "../components/HeaderService";
	import CampaignList from "../components/campaign/CampaignList";
	import CampaignForm from "../components/campaign/CampaignForm";

	export default {
		name: "campaign-service",

		components: {
			MenuService,
			HeaderService,
			CampaignList,
			CampaignForm
		},

		async mounted() {
			this.getAllCampaign();
			this.getService();
		},

		data() {
			return {
				service: {},

				campaigns: [],
				campaign: {
					id: null,
					name: null,
					media_channel_id: null,
					offer_id: null,
					company_id: null,
					type_id: null,
					campaign_id: null,
					status: "active",
					keyword: null
				},

				render: 1,
				serviceService: new ServiceService(),
				campaignService: new CampaignService()
			};
		},

		methods: {
			add() {
				$("#form").trigger("reset");
				$("#company_id").attr("disabled", "disabled");

				this.campaign = {
					id: null,
					name: null,
					media_channel_id: null,
					offer_id: null,
					company_id: null,
					type_id: null,
					campaign_id: null,
					status: "active"
				};

				$("#add").click();
			},

			edit(campaign) {
				this.campaign = Object.assign({}, campaign);
			},

			async getAllCampaign() {
				this.campaigns = await this.campaignService.fetchAllCampaigns(
					this.$route.params.id
				);
				this.render++;
			},

			async destroy(campaign) {
				await this.campaignService.destroy(campaign);
				await this.getAllCampaign();
			},

			getService() {
				this.campaignService.isLoading = true;
				this.serviceService
					.getService(this.$route.params.id)
					.then(response => {
						this.service = response.data;
						this.campaignService.isLoading = false;
					});
			}
		}
	};
</script>

<style>
</style>
