/* global axios */

export default class OfferService {
  constructor() {
    this.axios = axios;
  }

  /**
   * Get offers by service
   * @param serviceId
   * @returns {AxiosPromise<any> | *}
   */
  offers(serviceId) {
    const endpoint = `service/offers/${serviceId}`;
    return this.axios.get(endpoint);
  }

  /**
   * Store/Update service offers
   * @param serviceId
   * @param offers
   * @returns {AxiosPromise<any> | *}
   */
  save(serviceId, offers) {
    const endpoint = `service/offers/${serviceId}`;
    return this.axios.post(endpoint, offers);
  }

  /**
   * Get all keywords
   * @param serviceId
   * @returns {AxiosPromise<any> | *}
   */
  optInKeywords(serviceId) {
    const endpoint = `service/keywords/opt-in/${serviceId}`;
    return this.axios.get(endpoint);
  }

  updateKeywords(keywords) {
    return this.axios.put('service/keywords/opt-in', keywords);
  }

  getKeywordsByChannel(offerId, channel) {
    return this.axios.get(`/service/offers/${offerId}/keywords/${channel}`)
  }
}
