/* global axios */
export default class CampaignService {
  constructor() {
    this.isLoading = false;
    this.axios = axios;
  }

  async fetchAllCampaigns(serviceId) {
    return this.axios.get(`service/campaign/${serviceId}`).
      then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async store(serviceId, campaign) {
    this.isLoading = true;

    return this.axios.post(`service/campaign/${serviceId}`, campaign)
      .then(() => {
        this.fetchAllCampaigns(serviceId);
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
        return this.validator(error.response.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async update(serviceId, campaign) {
    this.isLoading = true;

    return this.axios.put(`service/campaign/${serviceId}/${campaign.id}`, campaign)
      .then(() => {
        this.fetchAllCampaigns(serviceId);
        return true;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
        return this.validator(error.response.data.errors);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async destroy(campaign) {
    await this.axios.delete(`service/campaign/${campaign.service_id}/${campaign.id}`);
  }

  async getTypes(serviceId) {
    this.isLoading = true;

    return this.axios.get(`/service/${serviceId}/campaigns/types`).
      then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  validator(messages) {
    const validates = [];
    Object.keys(messages).forEach(key => {
      validates.push(messages[key][0]);
    });

    return validates;
  }
}
